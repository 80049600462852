// Generated by Framer (f082bd6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ZlDnC5Pvd", "Yla_GpO8R"];

const serializationHash = "framer-GA1oY"

const variantClassNames = {Yla_GpO8R: "framer-v-5kdbgh", ZlDnC5Pvd: "framer-v-2khg3p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {abrir: "ZlDnC5Pvd", Fechar: "Yla_GpO8R"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, EfgF5KW7Q: click ?? props.EfgF5KW7Q, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZlDnC5Pvd"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EfgF5KW7Q, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ZlDnC5Pvd", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap176cu5q = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (EfgF5KW7Q) {const res = await EfgF5KW7Q(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "Yla_GpO8R") return false
return true
}

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-2khg3p", className, classNames)} data-framer-name={"abrir"} data-highlight layoutDependency={layoutDependency} layoutId={"ZlDnC5Pvd"} onTap={onTap176cu5q} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Yla_GpO8R: {"data-framer-name": "Fechar"}}, baseVariant, gestureVariant)}><motion.div className={"framer-34fqeu"} layoutDependency={layoutDependency} layoutId={"x5RLOrTLr"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{Yla_GpO8R: {rotate: -45}}}/>{isDisplayed() && (<motion.div className={"framer-k4vwv8"} layoutDependency={layoutDependency} layoutId={"Ll08poCaD"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2}}/>)}<motion.div className={"framer-i3houq"} layoutDependency={layoutDependency} layoutId={"VZdNW7v6n"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{Yla_GpO8R: {rotate: 45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GA1oY.framer-1n958ej, .framer-GA1oY .framer-1n958ej { display: block; }", ".framer-GA1oY.framer-2khg3p { cursor: pointer; height: 42px; overflow: hidden; position: relative; width: 46px; }", ".framer-GA1oY .framer-34fqeu { flex: none; height: 6px; left: calc(50.00000000000002% - 32px / 2); overflow: hidden; position: absolute; top: 6px; width: 32px; will-change: var(--framer-will-change-override, transform); }", ".framer-GA1oY .framer-k4vwv8 { flex: none; height: 6px; left: calc(50.00000000000002% - 32px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 6px / 2); width: 32px; will-change: var(--framer-will-change-override, transform); }", ".framer-GA1oY .framer-i3houq { bottom: 6px; flex: none; height: 6px; left: calc(50.00000000000002% - 32px / 2); overflow: hidden; position: absolute; width: 32px; will-change: var(--framer-will-change-override, transform); }", ".framer-GA1oY.framer-v-5kdbgh .framer-34fqeu { top: calc(50.00000000000002% - 6px / 2); }", ".framer-GA1oY.framer-v-5kdbgh .framer-i3houq { bottom: unset; top: calc(50.00000000000002% - 6px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Yla_GpO8R":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"EfgF5KW7Q":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPiAWMHYVM: React.ComponentType<Props> = withCSS(Component, css, "framer-GA1oY") as typeof Component;
export default FramerPiAWMHYVM;

FramerPiAWMHYVM.displayName = "Ícone_Manu";

FramerPiAWMHYVM.defaultProps = {height: 42, width: 46};

addPropertyControls(FramerPiAWMHYVM, {variant: {options: ["ZlDnC5Pvd", "Yla_GpO8R"], optionTitles: ["abrir", "Fechar"], title: "Variant", type: ControlType.Enum}, EfgF5KW7Q: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerPiAWMHYVM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})